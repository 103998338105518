import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import Content, { HTMLContent } from "../components/home/Content";

export const EndUserLicenseAgreementPageTemplate = ({
  header,
  title,
  subheader,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="flex-grow">
      <div className="lg:max-w-7xl mx-auto px-4 mt-4">
        <div className="py-6 text-center">
          <h1 className="text-lg text-green-dense font-semibold tracking-wide uppercase">
            {header}
          </h1>
          <h2 className="mt-2 text-6xl font-extrabold tracking-tight text-green-dense">
            {title}
          </h2>
          <h3 className="mt-4 mb-8 max-w-2xl mx-auto text-2xl font-normal text-green-dense">
            {subheader}
          </h3>
          <PageContent
            className="py-2 pb-6 px-5 bg-white shadow-2xl rounded-3xl text-justify"
            content={content}
          />
        </div>
      </div>
    </section>
  );
};

EndUserLicenseAgreementPageTemplate.propTypes = {
  header: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const EndUserLicenseAgreementPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <EndUserLicenseAgreementPageTemplate
        contentComponent={HTMLContent}
        header={post.frontmatter.header}
        title={post.frontmatter.title}
        subheader={post.frontmatter.subheader}
        content={post.html}
      />
    </Layout>
  );
};

EndUserLicenseAgreementPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EndUserLicenseAgreementPage;

export const endUserLicenseAgreementPageQuery = graphql`
  query EndUserLicenseAgreementPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        header
        title
        subheader
      }
    }
  }
`;
